import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner";
import "./index.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Hochzeit 05.09.25" />
    <Banner illustration={
      <StaticImage
          src={`../images/Zurich/wedding_logo_noBackground.png`}
          loading="eager"
          height={765}
          quality={98}
          formats={["auto", "webp", "avif"]}
          alt="Love"
      />
    } title={"Save The Date"}/>
      <div className={"wedding"}>
          Mier Hüratet! &nbsp;&nbsp;&nbsp;Ci sposiamo!
      </div>
      <div>
         Reservier der de <b>Friitig 05.09 & de Samstig 06.09.2025</b> i dim Kaländer
      </div>
      <br/>
      <div>
          Reserviere dir den<b> Freitag, 05.09 & den Samstag, 06.09.2025 </b>in deinem Kalender
      </div>
      <br/>
      <div>
          Save the date <b>Friday, September 5th and Saturday, September 6th, 2025</b>
      </div>
      <br/>
      <div>
          Segnatevi il <b>venerdì 5 e sabato 6 settembre 2025</b>
      </div>
      <hr/>

      {/*<iframe*/}
      {/*    src="https://docs.google.com/forms/d/e/1FAIpQLScW6OKf_9I0AvsV0GY68_VdkTW8yhb_Rtjd5gkqNg2D8PFM3A/viewform?embedded=true"*/}
      {/*    width="740" height="1145" frameBorder="0" marginHeight="0" marginWidth="0">Loading…*/}
      {/*</iframe>*/}
          <div>
          Die Planung ist in vollem Gange. Damit wir dir/euch dann die Einladung an den richtigen Ort schicken, füll(t) bitte <a href="https://forms.gle/SnBKs74qQLGUkHgJ8">dieses Formular</a> mit deiner/eurer aktuellen Adresse aus=)
          </div>
      <br/>
      <div>
      We are fully in the planning phase. Please,  <a href="https://forms.gle/SnBKs74qQLGUkHgJ8">fill out your address</a> so we can reach you with the invitation.
      </div>
          <br/>
          <div>
              Stiamo organizzando tutto per il grande giorno. Vi preghiamo di compilare <a href="https://forms.gle/SnBKs74qQLGUkHgJ8">questo modulo</a> con il vostro indirizzo attuale, in modo che vi possiamo inviare l'invito al posto giusto.

          </div>

  </Layout>

)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="05.09.25" />

export default IndexPage
